$(document).on('click', '.login-trigger', function() {
    $('.header-login-form').animate({width: 'toggle', height: '30px'}, function() {
        $('#email').focus();
    });
});
$(document).ready(function(){
    $('.bxslider').bxSlider({
        pager: false,
        controls: false,
        auto: false,
        speed: 2500
    });
});

/**
 * @Todo: Request Packages/modules from backend
 */
$(document).ready(function(){
    if ($('#account_type').length != 0 && $('#quota').length != 0) {
        var accountConditions = {
            'basic': {
            '20' : 149,
            '50' : 249,
            '100': 349,
            '200': 499,
            '300': 599,
            '400': 699,
            '500': 799,
            },
            'business' : {
            '20' : 199,
            '50' : 299,
            '100': 449,
            '200': 649,
            '300': 799,
            '400': 899,
            '500': 999,
            },
            'professional': {
            '20' : 249,
            '50' : 399,
            '100': 549,
            '200': 799,
            '300': 'auf Anfrage',
            '400': 'auf Anfrage',
            '500': 'auf Anfrage',
            },
        };

        var accountModules = {
            'basic': [
                'Aussagefähiger und leicht verständlicher Ergebnisbericht',
                'Alle Online-Assessments',
                'Kandidatenverwaltung in Projektordnern',
                'Mehrfacheinladungen via Token',
            ],
            'business' : [
                'Aussagefähiger und leicht verständlicher Ergebnisbericht',
                'Alle Online-Assessments',
                'Kandidatenverwaltung in Projektordnern',
                'Mehrfacheinladungen via Token',
                'Modulares Baukastensystem für funktionsspezifische Assessments',
                'Interviewleitfäden',
                'Entwicklungstipps',
                'Teamergebnisse',
                'Beliebig viele Anwender',
            ],
            'professional': [
                'Aussagefähiger und leicht verständlicher Ergebnisbericht',
                'Alle Online-Assessments',
                'Kandidatenverwaltung in Projektordnern',
                'Mehrfacheinladungen via Token',
                'Modulares Baukastensystem für funktionsspezifische Assessments',
                'Interviewleitfäden',
                'Entwicklungstipps',
                'Teamergebnisse',
                'Beliebig viele Anwender',
                'Anforderungen festlegen und automatisch abgleichen',
                'Unternehmenspezifische skills',
                'Schnittstelle zu Bewerbermanagementsystemen'
            ],
        }

        var account_type = $('#account_type').val()
        var quota = $('#quota').val()

        if(accountConditions[account_type][quota] !== undefined) {
            var price = accountConditions[account_type][quota];
            if (typeof price === 'string') {
                $('.price-wrapper').html(accountConditions[account_type][quota]);
                $('.can-register').hide();
                $('.can-contact').show();
            } else {
                $('.price-wrapper').html(accountConditions[account_type][quota]+' &euro; / Monat');
                $('.can-register').show();
                $('.can-contact').hide();
            }
        } else {
            $('.price-wrapper').html('Bitte wählen Sie ein gültiges Paket aus');
        }

        if(accountModules[account_type]) {
            var cList = $('ul.condition-list').html('');
            $.each(accountModules[account_type], function(i)
            {
                var li = $('<li/>')
                    .text(accountModules[account_type][i])
                    .appendTo(cList);
            });
        } else {
            $('.condition-wrapper').html('Bitte wählen Sie ein gültiges Paket aus');
        }

        $(document).on('change', '#account_type', function() {
            var account_type = $('#account_type').val()
            var quota = $('#quota').val()

            $("#quota option").each(function() {
                var $thisOption = $(this);
                $thisOption.attr("disabled", false);
            });

            if(accountConditions[account_type][quota] !== undefined) {
                var price = accountConditions[account_type][quota];
                if (typeof price === 'string') {
                    $('.price-wrapper').html(accountConditions[account_type][quota]);
                    $('.can-register').hide();
                    $('.can-contact').show();
                } else {
                    $('.price-wrapper').html(accountConditions[account_type][quota]+' &euro; / Monat');
                    $('.can-register').show();
                    $('.can-contact').hide();
                }
            } else {
                $('.price-wrapper').html('Bitte wählen Sie ein gültiges Paket aus');
            }

            if(accountModules[account_type]) {
                var cList = $('ul.condition-list').html('');
                $.each(accountModules[account_type], function(i)
                {
                    var li = $('<li/>')
                        .text(accountModules[account_type][i])
                        .appendTo(cList);
                });
            } else {
                $('.condition-wrapper').html('Bitte wählen Sie ein gültiges Paket aus');
            }
        });

        $(document).on('change', '#quota', function() {
            var account_type = $('#account_type').val();
            var quota = $('#quota').val();

            if(accountConditions[account_type][quota] !== undefined) {
                var price = accountConditions[account_type][quota];
                if (typeof price === 'string') {
                    $('.price-wrapper').html(accountConditions[account_type][quota]);
                    $('.can-register').hide();
                    $('.can-contact').show();
                } else {
                    $('.price-wrapper').html(accountConditions[account_type][quota]+' &euro; / Monat');
                    $('.can-register').show();
                    $('.can-contact').hide();
                }
            } else {
                $('.price-wrapper').html('Bitte wählen Sie ein gültiges Paket aus');
            }
        });
    }
});

/**
 * Smooth animation on #-click
 */
document.querySelectorAll('a[href^="#"]:not([class^=accordion-toggle]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        if(this.getAttribute('href') !== '#') {
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        }
    });
});
